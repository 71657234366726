* {
  box-sizing: border-box;
  font-style: normal;
  font-family: 'SF UI Text', sans-serif;
}

html {
  height: 30%;
}

body {
  background-image: linear-gradient(to right, #820978, #D94358);
  background-size: 120% 100%;
}

.popupButton:hover {
  background-color: #fccc63 !important;
  color: white !important;
  text-shadow: 0px 0px 3px #d8a434 !important;
}

.col-5, .col-6 {
  max-height: 800px !important;
}

@media (max-width: 765px) {
  body {
    background-size: 145% 100%;
    width: 450px;
    overflow-x: hidden;
  }

  .container > div {
    flex-direction: column-reverse;
  }

  h2 {
    margin-left: 15.5%;
    font-size: 3.73em !important;
    padding-left: 0%;
    padding-bottom: 2.2%;
  }

  .col-6 {
    align-items: center;
  }

  .popupButton {
    margin-top: 55% !important;
    white-space: nowrap !important;
    margin-bottom: 40% !important;
    position: static !important;
    margin-left: 20% !important;
    height: 100px !important;
    max-width: 400px !important;
  }

  h1 {
    left: 291px !important;
  }

  img {
    width: 300%;
    padding-top: 58%;
    padding-left: -2%;
    padding-right: 29%;
  }

  footer {
    padding: 210px !important;
  }
}

@media (min-width: 766px) and (max-width: 899px) {
  body {
    background-size: 150% 100%;
  }

  .container {
    margin-top: 20%;
  }

  img {
    height: 49.2% !important;
    padding-left: 0 !important;
    padding-right: 70%;
  }

  div.col-6 {
    margin-right: 1%;
  }

  h1 {
    left: 60%;
  }

  h2 {
    font-size: 2.3em !important;
    margin-left: 1.5%;
  }

  .popupButton {
    margin-top: 13% !important;
    white-space: nowrap !important;
    width: 80% !important;
    height: 8% !important;
    font-size: 11px !important;
    margin-left: 2.6% !important;
  }
}

@media (min-width: 899.1px) and (max-width: 991px) {
  body {
    background-size: 130% 100%;
  }

  img {
    height: 59% !important;
  }

  .popupButton {
    width: 300px !important;
    margin-left: 0% !important;
  }
}

@media (min-width: 991.1px) and (max-width: 1399px) {
  body {
    background-size: 120% 100%;
  }

  img {
    height: 69% !important;
  }

  .popupButton {
    width: 350px !important;
    margin-left: 0% !important;
  }
}